import raw from "./nodes.json";

const nodes = raw.sort(({ title: a }, { title: b }) => a.localeCompare(b));

const idsToNames = nodes.reduce(
  (acc, { id, title }) => ({ ...acc, [id]: title }),
  {},
);

const reverseLinks = nodes.reduce((acc, { id, links }) => {
  if (!links) {
    return acc;
  }
  var copy = { ...acc };
  links.forEach(({ id: linkId, description }) => {
    copy[linkId] = [...(copy[linkId] ?? []), { id, description }];
  });
  return copy;
}, {});

export { nodes, idsToNames, reverseLinks };
