/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Sigma } from "react-sigma";
import ForceLink from "react-sigma/lib/ForceLink";
import { nodes, idsToNames, reverseLinks } from "./nodes";

const Detail = ({ id, title, urls, description, links, setCurrentNodeId }) => (
  <div>
    <a name={id}></a>
    <h1>{title}</h1>
    {urls?.map(url => (
      <a href={url} key={url} target="_blank" rel="noopener noreferrer">
        <small>{url}</small>
      </a>
    ))}
    <ReactMarkdown source={description} />
    <ul>
      {[...(links ?? []), ...(reverseLinks[id] ?? [])]
        .sort(({ id: a }, { id: b }) =>
          idsToNames[a].localeCompare(idsToNames[b]),
        )
        .map(({ id, description }) => (
          <li key={id}>
            <a
              key={id}
              role="button"
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={() => {
                setCurrentNodeId(id);
              }}
            >
              {idsToNames[id]}
            </a>{" "}
            <ReactMarkdown source={description} />
          </li>
        ))}
    </ul>
  </div>
);

Detail.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  urls: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
};

const Details = ({ nodes }) => {
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const currentNode = currentNodeId
    ? nodes.find(node => node.id === currentNodeId)
    : null;

  const hoveredNode = hoveredNodeId
    ? nodes.find(node => node.id === hoveredNodeId)
    : null;

  const displayNode = hoveredNode || currentNode;

  const setOrToggleCurrentNodeId = id =>
    setCurrentNodeId(prev => (id === prev ? null : id));

  return (
    <div>
      <Sigma
        graph={{
          nodes: nodes.map(({ id, title }, i) => ({
            id,
            label: title,
            size: 1,
            x: i % 10,
            y: Math.floor(i / 10),
          })),
          edges: nodes.reduce(
            (acc, { id, links }) =>
              links
                ? [
                    ...acc,
                    ...links.map(({ id: linkId, description }) => ({
                      id: Math.random(),
                      source: id,
                      target: linkId,
                      label: description,
                    })),
                  ]
                : acc,
            [],
          ),
        }}
        settings={{ clone: false, autoRescale: true }}
        onClickNode={event => {
          setOrToggleCurrentNodeId(event.data.node.id);
        }}
        onOverNode={event => {
          setHoveredNodeId(event.data.node.id);
        }}
        onOutNode={() => {
          setHoveredNodeId(null);
        }}
        autoRescale={true}
      >
        <ForceLink startingIterations={3} iterationsPerRender={3} />
      </Sigma>
      {displayNode && (
        <Detail
          key={displayNode.id}
          id={displayNode.id}
          title={displayNode.title}
          urls={displayNode.urls}
          description={displayNode.description}
          links={displayNode.links}
          setCurrentNodeId={setCurrentNodeId}
        />
      )}
    </div>
  );
};

render(
  <div>
    <Details nodes={nodes} />
  </div>,
  document.getElementById("root"),
);
